// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".isi-content h4{color:var(--color-secondary);font-weight:700}.isi-content .iri-section{padding:0 0 1em}.isi-content .iri-section:first-of-type .inner-iri-section{border-bottom:1px solid #ababab;padding-bottom:1em}.isi-content h3{font-size:1.25em;font-weight:400;color:var(--color-secondary)}.isi-content h4{font-size:1.15em}.isi-content .highlight{font-size:1.2em;font-weight:700}.isi-content a{color:var(--color-secondary);text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00a0df",
	"secondaryColor": "#003479",
	"accentColor": "#c41230",
	"redBright": "#eb1700"
};
module.exports = exports;
