




















import Vue from 'vue';
import { Component} from 'vue-property-decorator';


@Component<TremfyaFooter>({})
export default class TremfyaFooter extends Vue {

}
