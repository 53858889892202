





































































































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '../../store/layout';
import IdleTimeout from '~/components/session/IdleTimeout.vue';
// import ConfigurationDrawer from '~/components/configuration/ConfigurationDrawer.vue';
@Component<Header>({
    components: {
        // ConfigurationDrawer,
        IdleTimeout,
    },
})
export default class Header extends Vue {
    @Resolve
    public layout!: LayoutModule;

    public get drawer() {
        return this.layout.drawer;
    }
    public set drawer(v) {
        this.layout.setDrawer(v);
    }

    dialogueShouldOpen=false;
     toggleValue=true

    isMm1 = this.layout.program === 'mm1';
    isTracleer = this.layout.program === 'tracleer';
    isTremfya = this.layout.program === 'tremfya';
    isRybrevant = this.layout.program === 'rybrevant';
    isSimponi = this.layout.program === 'simponi';
    isStelara = this.layout.program === 'stelara';
    isErleada = this.layout.program === 'erleada';
    isOpsumit = this.layout.program === 'pah';
    isStelaraStandard = this.layout.program === 'stelaraStandard';

    @Watch('layout.program', { deep: true })
    public watchLayoutProgramName() {
        this.isMm1 = this.layout.program === 'mm1';
        this.isTracleer = this.layout.program === 'tracleer';
        this.isTremfya = this.layout.program === 'tremfya';
        this.isRybrevant = this.layout.program === 'rybrevant';
        this.isStelara = this.layout.program === 'stelara';
        this.isSimponi = this.layout.program === 'simponi';
        this.isErleada = this.layout.program === 'erleada';
        this.isOpsumit = this.layout.program === 'pah';
        this.isStelaraStandard = this.layout.program === 'stelaraStandard';
    }
    @Watch('layout.configuration.programName', { deep: true })
    public watchProgramName(programName: string) {
        this.layout.replaceProgramName(programName, this.layout.previousProgramName);
    }

    togglebox(){
        this.toggleValue =false
    }
    modalbox(){
        this.dialogueShouldOpen=true;
    }

    public get needHelpImg() {
        const url = this.layout.program === 'pah' ? this.$settings.needHelpPHImage : this.$settings.needHelpImage
        return this.$settings.url(url)
    }

    // public get headerRightImageSrc(){
    //     return '/NeedHelpPng.png'
    // }

    public get hasActiveSession() {
        return !this.layout.buttonsDisabled;
    }

    public get isAbsolute() {
        return this.layout.configuration.logoPosition !== 'start';
    }

    public get isScrolling() {
        return this.layout.configuration.headerStyle === 'scrolling';
    }
    public get brkPoints() {
        return {
            'brk-xs': this.$vuetify.breakpoint.xs,
            'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
            'brk-sm': this.$vuetify.breakpoint.sm,
            'brk-sm-only': this.$vuetify.breakpoint.smOnly,
            'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
            'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
            'brk-md': this.$vuetify.breakpoint.md,
            'brk-md-only': this.$vuetify.breakpoint.mdOnly,
            'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
            'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
            'brk-lg': this.$vuetify.breakpoint.lg,
            'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
            'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
            'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
            'brk-xl': this.$vuetify.breakpoint.xl,
            'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
        };
    }

    handleClick() {
        this.$vuetify.goTo('#main-footer', {
            duration: 1000,
            easing: 'easeInOutCubic',
            offset: 70,
        });
    }
}
