





































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';
import { Title } from '~/decorators/Title';
// import EligibilityModule from '~/store/eligibility';

@Component<Index>({
    layout: 'default',
    methods: {
        navigateToDrugsProgramRequirements() {
            if (this.program === 'tremfya') {
                const routeData = this.$router.resolve({ path: '/program-requirements', query: { program: 'tremfya' } });
                window.open(routeData.href, '_blank');
            } else if (this.program === 'stelara') {
                const routeData = this.$router.resolve({ path: '/program-requirements', query: { program: 'stelara' } });
                window.open(routeData.href, '_blank');
            } else if (this.program === 'stelara') {
                const routeData = this.$router.resolve({ path: '/program-requirements', query: { program: 'erleada' } });
                window.open(routeData.href, '_blank');
            } else if (this.program === 'stelara') {
                const routeData = this.$router.resolve({ path: '/program-requirements', query: { program: 'pah' } });
                window.open(routeData.href, '_blank');
            }
        },
    },
})
@Title(process.env.NAME!)
export default class Index extends Vue {
    @Resolve
    public layout!: LayoutModule;

    program = this.layout.program;

    isTremfya = this.program === 'tremfya';
    isStelara = this.program === 'stelara';
    isErleada = this.program === 'erleada';
    isOpsumit = this.program === 'pah';

    get prescribingInfoLink() {
        if (this.isTremfya) {
            return 'https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/TREMFYA-pi.pdf';
        } else if (this.isStelara) {
            return 'https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/STELARA-pi.pdf';
        } else if (this.isErleada) {
            return 'https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/ERLEADA-pi.pdf';
        } else if (this.isOpsumit) {
            return 'https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/OPSUMIT-pi.pdf';
        } else {
            return '';
        }
    }

    get medicationGuideLink() {
        if (this.isTremfya) {
            return 'https://www.janssenlabels.com/package-insert/product-patient-information/TREMFYA-medication-guide.pdf';
        } else if (this.isStelara) {
            return 'https://www.janssenlabels.com/package-insert/product-patient-information/STELARA-medication-guide.pdf';
        } else if (this.isErleada) {
            return '';
        } else if (this.isOpsumit) {
            return 'https://www.janssenlabels.com/package-insert/product-patient-information/OPSUMIT-medication-guide.pdf';
        } else {
            return '';
        }
    }

    get drugName() {
        if (this.isTremfya) {
            return `TREMFYA<sup>&reg;</sup>`;
        } else if (this.isStelara) {
            return 'STELARA<sup>&reg;</sup>';
        } else if (this.isErleada) {
            return 'ERLEADA<sup>&reg;</sup>';
        } else if (this.isOpsumit) {
            return 'OPSUMIT<sup>&reg;</sup>';
        } else {
            return '';
        }
    }

    public get brkPoints() {
        return {
            'brk-xs': this.$vuetify.breakpoint.xs,
            'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
            'brk-sm': this.$vuetify.breakpoint.sm,
            'brk-sm-only': this.$vuetify.breakpoint.smOnly,
            'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
            'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
            'brk-md': this.$vuetify.breakpoint.md,
            'brk-md-only': this.$vuetify.breakpoint.mdOnly,
            'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
            'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
            'brk-lg': this.$vuetify.breakpoint.lg,
            'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
            'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
            'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
            'brk-xl': this.$vuetify.breakpoint.xl,
            'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
        };
    }
}
