













































import Vue from 'vue';
import { Resolve } from 'vue-di';
import { Component, Prop } from 'vue-property-decorator';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import SessionModule from '~/store/session';
import DocumentModule from '~/store/document';
import EnrollmentModule from '~/store/enrollment';
import { Settings } from '~/services/settings';

@Component<IdleTimeout>({
    components: {
    },
    icons: {
        faClock,
    },
})
export default class IdleTimeout extends Vue {
    @Resolve
    public session!: SessionModule;
    @Resolve
    public settings!: Settings;
    @Resolve
    public document!: DocumentModule;
    @Resolve
    public enrollment!: EnrollmentModule;

    @Prop()
    public shouldDoTimeout!: Function;

    public timeoutMinutes = Number(this.settings.sessionTimeout ?? 15);
    public timeoutEnabled = false;
    public showWarning = false;
    public enableLastChanceDialog = false;
    public countdownSeconds = 59;
    private countdown: number | undefined;

    private get isLandingPage() {
        return this.$route.name === 'index';
    }

    private get layout() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.$parent as any).layout || (this.$parent.$parent as any).layout;
    }

    created() {
        this.onIdle();
        [ 'keydown', 'mousedown', 'mousemove', 'touchstart' ].forEach(evt => document.addEventListener(evt, this.onActive, false));
    }

    async onActive() {
        window.clearTimeout(this.countdown);
        this.onIdle();
    }

    async onIdle() {
        this.countdown = window.setTimeout(this.warn, this.timeoutMinutes * 60000);
    }

    warn() { 
        if (!this.isLandingPage || this.shouldDoTimeout) {
            this.showWarning = this.enableLastChanceDialog;
            this.countdown = window.setTimeout(this.logout, 60000 * (this.enableLastChanceDialog ? 1 : 0));

            if (this.enableLastChanceDialog) {
                window.setInterval(() => { if (this.countdownSeconds > 0) this.countdownSeconds--; }, 1000);
            }
        }
    }

    stay() {
        this.showWarning = false;
        this.onIdle();
    }

    logout() {
        this.showWarning = false;

        this.enrollment.clearStore();

        if (this.document?.hasDocument) {
            this.document.clear();
        }

        if (this.session?.isAuthenticatedUser) {
            this.session.logout();
        }

        if (this.layout && this.layout.setButtonsDisabled) {
            this.layout.setButtonsDisabled(true);
        }

        this.$router.push({ path: '/', query: { sessionClosedAt: (new Date()).toLocaleTimeString().replace(' ', '').replaceAll(':', '.') } });
    }
};
