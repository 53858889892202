
































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import Mm1Footer from './Mm1Footer.vue';
import TracleerFooter from './TracleerFooter.vue';
import TremfyaFooter from './TremfyaFooter.vue';
import SimponiFooter from './SimponiFooter.vue';
import LayoutModule from '~/store/layout';
import MedPrescribingInfo from '~/components/MedPrescribingInfo.vue';
import Mm1 from '~/pages/program-requirements/mm1.vue';
import PathModule from '~/store/path';
@Component<Footer>({
    components: {
        MedPrescribingInfo,
        Mm1Footer,
        TracleerFooter,
        TremfyaFooter,
    },
})
export default class Footer extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Resolve
    public path!: PathModule;

    @Prop()
    public brkPoints!: object;
    @Prop({ default: false })
    public isFixed!: boolean;

    isMm1 = this.layout.program === 'mm1';
    isTracleer = this.layout.program === 'tracleer';
    isTremfya = this.layout.program === 'tremfya';
    isRybrevant = this.layout.program === 'rybrevant';
    isSimponi = this.layout.program === 'simponi';
    isStelara = this.layout.program === 'stelara';
    isErleada = this.layout.program === 'erleada';
    isOpsumit = this.layout.program === 'pah';
    isStelaraStandard = this.layout.program === 'stelaraStandard';

    footerKey = 0;
    mounted() {
        this.layout.replaceProgramName(this.layout.configuration.programName, this.layout.previousProgramName);
    }

    get footerForRybreventProgramRequirements(){
        return this.isRybrevant && this.$route.fullPath.startsWith('/program-requirements/rybrevant')
    }

    get programSpecificFooter() {
        if (this.isStelara || this.isSimponi) {
            return true;
        }
        if (this.isSimponi) {
            return false;
        } else {
            return false;
        }
    }

    get getProgramName() {
        if (this.isStelara || this.isStelaraStandard) {
            return 'STELARA';
        } else if (this.isSimponi) {
            return 'SIMPONI';
        }
        // else if (this.isStelaraStandard) {
        //     return 'STELARASTANDARD';
        // }
        return '';
    }
    get getPrescriberLink() {
        if (this.isStelara || this.isStelaraStandard) {
            return 'https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/STELARA-pi.pdf';
        } else if (this.isSimponi) {
            return 'https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/SIMPONI-pi.pdf';
        } else if (this.isRybrevant) {
            return 'https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/RYBREVANT-pi.pdf';
        }
        return '';
    }

    get getMedicationGuideLink() {
        if (this.isStelara || this.isStelaraStandard) {
            return 'https://www.janssenlabels.com/package-insert/product-patient-information/STELARA-medication-guide.pdf';
        } else if (this.isSimponi) {
            return 'https://www.janssenlabels.com/package-insert/product-patient-information/SIMPONI-medication-guide.pdf';
        } else if (this.isRybrevant) {
            return 'https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/RYBREVANT-pi.pdf';
        }
        return '';
    }

    get phoneNumber() {
        return this.layout.program === 'pah' ? '1-866-228-3546' : '866-708-8987';
    }

    get showMedPrescLink() {
        const rebate = this.$route.path.includes('rebate');
        const card = this.$route.path.includes('card');
        const requirements = this.$route.path.includes('requirements');
        if (rebate || card || requirements) {
            return true;
        } else {
            return false;
        }
    }
    @Watch('layout.program', { deep: true })
    public watchLayoutProgramName() {
        this.footerKey += 1;
        this.isMm1 = this.layout.program === 'mm1';
        this.isTracleer = this.layout.program === 'tracleer';
        this.isTremfya = this.layout.program === 'tremfya';
        this.isRybrevant = this.layout.program === 'rybrevant';
        this.isStelara = this.layout.program === 'stelara';
        this.isSimponi = this.layout.program === 'simponi';
        this.isErleada = this.layout.program === 'erleada';
        this.isOpsumit = this.layout.program === 'pah';
        this.isStelaraStandard = this.layout.program === 'stelaraStandard';
    }

    @Watch('layout.configuration.programName', { deep: true })
    public watchProgramName(programName: string) {
        this.layout.replaceProgramName(programName, this.layout.previousProgramName);
    }

    public get footerInfoLinkLocation() {
        return this.layout.configuration.footerInfoLinkLocation;
    }

    public get isTopOrBottomBar() {
        return ['top', 'bottom'].includes(this.footerInfoLinkLocation);
    }

    public get isLeftOrRightBar() {
        return ['left', 'right'].includes(this.footerInfoLinkLocation);
    }
}
