import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b175d576 = () => interopDefault(import('..\\pages\\access-your-card.vue' /* webpackChunkName: "pages_access-your-card" */))
const _8291c598 = () => interopDefault(import('..\\pages\\card-print.vue' /* webpackChunkName: "pages_card-print" */))
const _4452b8a5 = () => interopDefault(import('..\\pages\\program-requirements\\index.vue' /* webpackChunkName: "pages_program-requirements_index" */))
const _9185164c = () => interopDefault(import('..\\pages\\rebate-form.vue' /* webpackChunkName: "pages_rebate-form" */))
const _b4509a70 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _a9e7f132 = () => interopDefault(import('..\\pages\\view-your-account.vue' /* webpackChunkName: "pages_view-your-account" */))
const _ab8fe056 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _ab73b154 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _ab3b5350 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _ab1f244e = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _41c369d4 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _3eac91a4 = () => interopDefault(import('..\\pages\\program-requirements\\mm1.vue' /* webpackChunkName: "pages_program-requirements_mm1" */))
const _2507df0d = () => interopDefault(import('..\\pages\\program-requirements\\program-requirement.vue' /* webpackChunkName: "pages_program-requirements_program-requirement" */))
const _825f8e1c = () => interopDefault(import('..\\pages\\program-requirements\\rybrevant.vue' /* webpackChunkName: "pages_program-requirements_rybrevant" */))
const _3e5954df = () => interopDefault(import('..\\pages\\program-requirements\\stelara-standard.vue' /* webpackChunkName: "pages_program-requirements_stelara-standard" */))
const _8facfa42 = () => interopDefault(import('..\\pages\\program-requirements\\tremfya-standard.vue' /* webpackChunkName: "pages_program-requirements_tremfya-standard" */))
const _109cb1fe = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-your-card",
    component: _b175d576,
    name: "access-your-card"
  }, {
    path: "/card-print",
    component: _8291c598,
    name: "card-print"
  }, {
    path: "/program-requirements",
    component: _4452b8a5,
    name: "program-requirements"
  }, {
    path: "/rebate-form",
    component: _9185164c,
    name: "rebate-form"
  }, {
    path: "/unlock",
    component: _b4509a70,
    name: "unlock"
  }, {
    path: "/view-your-account",
    component: _a9e7f132,
    name: "view-your-account"
  }, {
    path: "/errors/400",
    component: _ab8fe056,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _ab73b154,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _ab3b5350,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _ab1f244e,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _41c369d4,
    name: "errors-500"
  }, {
    path: "/program-requirements/mm1",
    component: _3eac91a4,
    name: "program-requirements-mm1"
  }, {
    path: "/program-requirements/program-requirement",
    component: _2507df0d,
    name: "program-requirements-program-requirement"
  }, {
    path: "/program-requirements/rybrevant",
    component: _825f8e1c,
    name: "program-requirements-rybrevant"
  }, {
    path: "/program-requirements/stelara-standard",
    component: _3e5954df,
    name: "program-requirements-stelara-standard"
  }, {
    path: "/program-requirements/tremfya-standard",
    component: _8facfa42,
    name: "program-requirements-tremfya-standard"
  }, {
    path: "/",
    component: _109cb1fe,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
